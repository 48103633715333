export const LANDING = "/" as const;
export const SIGN_UP = "/signup/" as const;
export const SIGN_IN = "/signin/" as const;
export const PASSWORD_FORGET = "/pw-forget/" as const;
export const HOME = "/home/" as const;
export const PHOTOGRAPHERS = "/photographers/" as const;
export const ABOUT = "/about/" as const;
export const WELCOME = "/welcome/" as const;
export const WAIT_LIST = "/waitlist/" as const;
export const MESSAGE_SENT = "/message-sent/" as const;
export const BLOG = "/blog/" as const;
export const FAQ = "/faq/" as const;

// client only routes
export const SETTINGS = "/settings/" as const;
export const SETTINGS_LINKS = "/settings/links/" as const;
export const SETTINGS_PHOTOS = "/settings/photos/" as const;
export const SETTINGS_TESTIMONIALS = "/settings/testimonials/" as const;
export const SETTINGS_ACCOUNT = "/settings/account/" as const;
export const SETTINGS_PUBLISH = "/settings/publish/" as const;
export const MESSAGES = "/messages/" as const;

export const PREMIUM = "/plus/" as const;
export const PREMIUM_SUCCESS = "/plus/success/" as const;
export const PREMIUM_CANCELLED = "/plus/cancelled/" as const;

export const ADMIN = "/admin/" as const;
