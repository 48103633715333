import React, { useContext, useState } from "react";
import Helmet from "react-helmet";

import * as colors from "../../constants/colors";
import cloudinary from "../../utils/cloudinary";
import FirebaseContext from "../Firebase/FirebaseContext";
import AuthUserContext from "../authentication/AuthUserContext";

const UploadWidgetContext = React.createContext(null);

// const WIDGET_SRC = "https://widget.cloudinary.com/v2.0/global/all.js";
const WIDGET_SRC = "https://upload-widget.cloudinary.com/global/all.js";

const UploadWidget = ({ children }) => {
  const authUser = useContext(AuthUserContext);
  const firebase = useContext(FirebaseContext);

  const [, setScript] = useState();
  const [widget, setWidget] = useState();
  const [uploadingProfilePicture, setUploadingProfilePicture] = useState(false); // otherwise photos
  const [onSuccess, setOnSuccess] = useState();

  const handleScriptInject = (addedTags) => {
    if (!addedTags) return;

    const { scriptTags } = addedTags;
    if (!scriptTags) return;

    const [cloudinaryScript] = scriptTags;
    if (!cloudinaryScript) return;

    cloudinaryScript.onload = () => {
      setScript(window.cloudinary);
      createUploadWidget(window.cloudinary);
    };
  };

  const createUploadWidget = (cloudinarySdk) => {
    if (!authUser) {
      return console.error("Cloudinary widget: No user");
    }
    if (widget) {
      return console.error("Cloudinary widget: Already created");
    }

    const uploadWidget = cloudinarySdk.createUploadWidget(
      {
        cloudName: cloudinary.CLOUD_NAME,
        //inlineContainer: '#' + this.iframeContainerRef.current.id,
        //inlineContainer: this.iframeContainerRef.current,
        uploadPreset: cloudinary.UPLOAD_PRESET,
        sources: ["local", "url", "facebook", "dropbox", "instagram"],
        defaultSource: "local",
        singleUploadAutoClose: false,
        showAdvancedOptions: false,
        //cropping: this.props.profilePicture, // can not be used with multiple
        //croppingAspectRatio: 1,
        resourceType: "image",
        maxImageWidth: 2000, // client-side down-resize images larger than 2000px width
        maxImageHeight: 2000, // client-side down-resize images larger than 2000px height
        maxFileSize: 1000000, // don't allow upload if larger than 1.0 MB (after down-resize)
        maxFiles: cloudinary.MAX_FILES, // only allow a limited number of images per user
        multiple: true,
        folder: cloudinary.PATH_TO_USER_IMAGES_FOLDER + authUser.uid,
        styles: {
          palette: {
            window: "#FFFFFF",
            windowBorder: "#000000",
            tabIcon: colors.BLUE,
            menuIcons: "#000000",
            textDark: "#000000",
            textLight: "#FFFFFF",
            link: colors.BLUE,
            action: colors.RED,
            inactiveTabIcon: colors.BLUE,
            error: "#F44235",
            inProgress: "#0078FF",
            complete: "#20B832",
            sourceBg: "#FFFFFF",
          },
          fonts: {
            "'Work Sans', sans-serif":
              "https://fonts.googleapis.com/css?family=Work+Sans",
          },
        },
      },
      uploadCallback,
    );
    setWidget(uploadWidget);
  };

  const openProfilePictureUploader = (onSuccess) => {
    setUploadingProfilePicture(true);
    setOnSuccess(onSuccess);

    widget.update({
      multiple: false,
      maxFiles: 1,
      folder: cloudinary.PATH_TO_PROFILE_PICTURE_FOLDER + authUser.uid,
    });
    widget.open();
  };

  const openPhotoUploader = (maxFiles, onSuccess) => {
    setUploadingProfilePicture(false);
    setOnSuccess(onSuccess);

    widget.update({
      multiple: true,
      maxFiles: maxFiles,
      folder: cloudinary.PATH_TO_USER_IMAGES_FOLDER + authUser.uid,
    });
    widget.open();
  };

  const uploadCallback = (error, result) => {
    if (error) console.error("Cloudinary widget error", error);
    //console.log('Cloudinary widget callback', result);
    if (result.event === "success") {
      if (uploadingProfilePicture) {
        firebase
          .doAddProfilePictureToUser(
            authUser.uid,
            result.info.public_id,
            result.info.width,
            result.info.height,
          )
          .then(onSuccess && onSuccess())
          .catch(console.error);
      } else {
        firebase
          .doAddImageToUser(
            authUser.uid,
            result.info.public_id,
            result.info.width,
            result.info.height,
          )
          .then(onSuccess && onSuccess())
          .catch(console.error);
      }
    } else if (result.event === "queues-end") {
    }
  };

  return (
    <>
      {!!authUser && (
        <Helmet
          script={[{ src: WIDGET_SRC }]}
          // Helmet doesn't support `onload` in script objects so we have to hack in our own
          onChangeClientState={(newState, addedTags) =>
            handleScriptInject(addedTags)
          }
        />
      )}
      <UploadWidgetContext.Provider
        value={{ openProfilePictureUploader, openPhotoUploader }}
      >
        {children}
      </UploadWidgetContext.Provider>
    </>
  );
};

export const withUploadWidget = (Component) => (props) => (
  <UploadWidgetContext.Consumer>
    {(uploadWidget) => <Component {...props} uploadWidget={uploadWidget} />}
  </UploadWidgetContext.Consumer>
);

export const UploadWidgetWrapper = (props) => (
  <FirebaseContext.Consumer>
    {(firebase) => (
      <AuthUserContext.Consumer>
        {(authUser) => (
          <UploadWidget {...props} authUser={authUser} firebase={firebase} />
        )}
      </AuthUserContext.Consumer>
    )}
  </FirebaseContext.Consumer>
);

export default UploadWidgetContext;
