import { User } from "functions/src/firestore";
import React, { useContext } from "react";

export type UserWithId = User & { id: string };

const UsersContext = React.createContext<UserWithId[] | undefined>(undefined);

export const withUsers = (Component) => (props) => (
  <UsersContext.Consumer>
    {(users) => <Component {...props} users={users} />}
  </UsersContext.Consumer>
);

export default UsersContext;

const isBrowser = typeof window !== "undefined";

export const useUsers = (): UserWithId[] | undefined => {
  const users = useContext(UsersContext);

  if (!isBrowser) {
    // workaround for SSR
    return undefined;
  }

  return users;
};
