import * as colors from "../../constants/colors";
import { Theme } from "./types";

const light: Theme = {
  colors: {
    isDark: false,
    background: colors.LIGHT_GREY,
    secondaryBackground: colors.WHITE,
    footerBackground: colors.DEEP_BLACK,
    buttonBackground: colors.WHITE,
    textColor: colors.BLACK,
    secondaryTextColor: colors.BLACK,
    tertiaryTextColor: `#767676`,
  },
} as const;

export default light;
