import React from "react";

import { withFirebase } from "../Firebase/FirebaseContext";
import { withRefreshUsers } from "../Firebase/RefreshUsersContext";
import AuthUserContext from "./AuthUserContext";
import UserContext from "./UserContext";

import * as analytics from "../../utils/analytics";
import cloudinary from "../../utils/cloudinary";
import * as crisp from "../../utils/crisp";

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        initFirebase: false,
        authUser: null,
        user: null,
      };
    }

    /**
     * When a user updates email, and then reverts the update
     * from the confirmation email from firebase,
     * the emails get out of sync.
     * This syncs the emails again upon login.
     */
    syncEmails = async (authUser) => {
      const document = await this.props.firebase.onceGetUser(authUser.uid);
      const user = document.data();
      if (user.email !== authUser.email) {
        console.warn("Email out of sync, updating...");
        this.props.firebase.doUpdateUser(authUser.uid, {
          email: authUser.email,
        });
      }
    };

    firebaseInit = () => {
      if (this.props.firebase && !this.state.initFirebase) {
        this.props.firebase.auth.onAuthStateChanged((authUser) => {
          if (authUser) {
            this.setState(() => ({ authUser, initFirebase: true }));

            this.syncEmails(authUser);

            this.props.firebase.analytics.setUserId(authUser.uid);
            analytics.setGoogleAnalyticsUserId(authUser.uid);
            crisp.setCrispUserField(`email`, authUser.email);

            this.props.firebase.onUserUpdate(authUser.uid, (snapshot) => {
              const user = snapshot.data();
              this.setState({
                user: Object.assign({ id: snapshot.id }, user),
              });

              if (user && user.firstName && user.lastName)
                crisp.setCrispUserField(
                  `nickname`,
                  `${user.firstName} ${user.lastName}`,
                );
              if (user && user.profilePicture)
                crisp.setCrispUserField(
                  `avatar`,
                  `${cloudinary.BASE_URL}w_200,h_200,c_fill,q_auto:good,f_auto/v1/${user.profilePicture.publicId}`,
                );
            });

            this.props.firebase.onUserPaymentsUpdate(
              authUser.uid,
              (snapshot) => {
                const user = this.state.user;
                const latestPayment = snapshot.docs.sort(
                  (a, b) =>
                    parseInt(b.data().created) - parseInt(a.data().created),
                )[0];
                if (
                  latestPayment &&
                  latestPayment.data().type === "checkout.session.completed"
                ) {
                  const createdAt = new Date(
                    parseInt(latestPayment.data().created) * 1000,
                  );
                  const plan = latestPayment
                    .data()
                    .dataDisplayItems.split(String.fromCharCode(10))[2]
                    .split(" ")[1];
                  if (
                    (user && !user.subscription) ||
                    (user &&
                      user.subscription &&
                      user.subscription.plan !== plan)
                  ) {
                    this.props.firebase.doUpdateUser(authUser.uid, {
                      subscription: {
                        plan: plan || "unknown",
                        createdAt: createdAt,
                      },
                    });
                  }
                }
              },
            );

            this.props.firebase.doUpdateLastSignInAt(
              authUser.uid,
              authUser.metadata.creationTime,
              authUser.metadata.lastSignInTime,
            );

            // let logged in users get the real time user list
            this.props.refreshUsers();
          } else {
            this.setState(() => ({
              authUser: null,
              initFirebase: true,
            }));
          }
        });
      }
    };

    componentDidMount() {
      this.firebaseInit();
    }

    componentDidUpdate() {
      this.firebaseInit();
    }

    render() {
      const { authUser, user } = this.state;

      return (
        <AuthUserContext.Provider value={authUser}>
          <UserContext.Provider value={user}>
            <Component {...this.props} />
          </UserContext.Provider>
        </AuthUserContext.Provider>
      );
    }
  }

  return withFirebase(withRefreshUsers(WithAuthentication));
};

export default withAuthentication;
