import { arrayUnion, serverTimestamp } from "firebase/firestore";

const prodConfig = {
  apiKey: "AIzaSyDT4C0NvyUcubqDMopFImdG24sKLY3yvbA",
  authDomain: "picture-it-production.firebaseapp.com",
  databaseURL: "https://picture-it-production.firebaseio.com",
  projectId: "picture-it-production",
  storageBucket: "picture-it-production.appspot.com",
  messagingSenderId: "884821755389",
  appId: "1:884821755389:web:1eab1e87ae7f06d0198594",
} as const;

const devConfig = {
  apiKey: "AIzaSyBjSOwTtlbyvDCsCnm1PUdxHwGjb73tVik",
  authDomain: "gatsby-firebase-12d78.firebaseapp.com",
  databaseURL: "https://gatsby-firebase-12d78.firebaseio.com",
  projectId: "gatsby-firebase-12d78",
  storageBucket: "gatsby-firebase-12d78.appspot.com",
  messagingSenderId: "906986369237",
  appId: "1:906986369237:web:4aba149ad3cebdec3ff25c",
} as const;

const config = process.env.NODE_ENV === "production" ? prodConfig : devConfig;

export class Firebase {
  db: any;
  auth: any;
  functions: any;
  analytics: any;
  performance: any;

  constructor(app) {
    app.initializeApp(config);

    this.db = app.firestore();
    this.auth = app.auth();
    this.functions = app.functions();
    this.analytics = app.analytics();
    this.performance = app.performance();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = ({ email, password }) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  // *** User API ***

  doCreateUser = ({ id, email, username, creationTime, lastSignInTime }) =>
    this.db
      .collection("users")
      .doc(id)
      .set({
        email,
        username,
        createdAt: new Date(creationTime),
        lastUpdatedAt: new Date(creationTime),
        lastSignInAt: new Date(lastSignInTime),
      });

  /**
   * we can remove creationTime when all users have the value set..
   */
  doUpdateLastSignInAt = (id, creationTime, lastSignInTime) =>
    this.db
      .collection("users")
      .doc(id)
      .update({
        createdAt: new Date(creationTime),
        lastSignInAt: new Date(lastSignInTime),
      });

  doUpdateUser = (id, update) => {
    update.lastUpdatedAt = serverTimestamp();
    return this.db.collection("users").doc(id).update(update);
  };

  doAddImageToUser = (userId, publicId, width, height) => {
    return this.db
      .collection("users")
      .doc(userId)
      .update({
        images: arrayUnion({
          publicId: publicId,
          width: width,
          height: height,
        }),
        lastUpdatedAt: serverTimestamp(),
      });
  };

  doUpdateAllImagesToUser = (userId, images) => {
    return this.db.collection("users").doc(userId).update({
      images: images,
      lastUpdatedAt: serverTimestamp(),
    });
  };

  doRemoveImagesFromUser = (userId, arrayOfPublicIds) => {
    return new Promise<void>((resolve, reject) => {
      return this.db
        .collection("users")
        .doc(userId)
        .get()
        .then((snapshot) => {
          let images = snapshot.data().images;
          if (images) {
            images = images.filter(
              (image) => !arrayOfPublicIds.includes(image.publicId),
            );
          }
          this.db
            .collection("users")
            .doc(userId)
            .update({
              images: images,
              lastUpdatedAt: serverTimestamp(),
            })
            .then(resolve());
        });
    });
  };

  doAddProfilePictureToUser = (userId, publicId, width, height) => {
    return this.db
      .collection("users")
      .doc(userId)
      .update({
        profilePicture: {
          publicId: publicId,
          width: width,
          height: height,
        },
        lastUpdatedAt: serverTimestamp(),
      });
  };

  doAddTestimonialToUser = (userId, image, name, testimonial) => {
    return this.db
      .collection("users")
      .doc(userId)
      .update({
        testimonials: arrayUnion({
          image: image,
          name: name,
          testimonial: testimonial,
        }),
        lastUpdatedAt: serverTimestamp(),
      });
  };

  doUpdateAllTestimonialsToUser = (userId, testimonials) => {
    return this.db.collection("users").doc(userId).update({
      testimonials: testimonials,
      lastUpdatedAt: serverTimestamp(),
    });
  };

  doAddNewContactFormResponse = async (userId, formResponse) => {
    return this.db
      .collection("users")
      .doc(userId)
      .collection("messages")
      .add({
        ...formResponse,
        readAt: false,
        answeredAt: false,
        receivedAt: serverTimestamp(),
        sentAt: serverTimestamp(),
      });
  };

  doReadMessage = (userId, messageId) => {
    return this.db
      .collection("users")
      .doc(userId)
      .collection("messages")
      .doc(messageId)
      .update({ readAt: serverTimestamp() });
  };

  doAnswerMessage = (userId, messageId) => {
    return this.db
      .collection("users")
      .doc(userId)
      .collection("messages")
      .doc(messageId)
      .update({ answeredAt: serverTimestamp() });
  };

  onceGetUser = (id) => this.db.collection("users").doc(id).get();

  onceGetUserMessages = (userId) =>
    this.db.collection("users").doc(userId).collection("messages").get();

  onUserMessages = (userId, callback) => {
    this.db
      .collection("users")
      .doc(userId)
      .collection("messages")
      .orderBy("sentAt", "desc")
      .onSnapshot((querySnapshot) => {
        callback(querySnapshot);
      });
  };

  onUserUnreadMessages = (userId, callback) => {
    this.db
      .collection("users")
      .doc(userId)
      .collection("messages")
      .where("readAt", "==", false)
      .orderBy("readAt", "desc")
      .onSnapshot((querySnapshot) => {
        callback(querySnapshot);
      });
  };

  onUserUpdate = (id, callback) => {
    this.db
      .collection("users")
      .doc(id)
      .onSnapshot(function (doc) {
        callback(doc);
      });
  };

  onUserPaymentsUpdate = (id, callback) => {
    this.db
      .collection("users")
      .doc(id)
      .collection("payments")
      .onSnapshot(function (doc) {
        callback(doc);
      });
  };

  onceGetUsers = () =>
    this.db.collection("users").orderBy("lastSignInAt", "desc").get();

  isUserNameAvailable = (username) =>
    this.functions.httpsCallable("isUserNameAvailable")({ username });

  updateEmail = async (userId, email) => {
    await this.auth.currentUser.updateEmail(email);
    await this.db.collection("users").doc(userId).update({ email });
  };

  // *** Analytics API ***

  logEvent = (event) => this.analytics.logEvent(event);
}

let firebase;

function getFirebase(app): Firebase {
  if (firebase) {
    return firebase;
  }

  firebase = new Firebase(app);

  return firebase;
}

export default getFirebase;
