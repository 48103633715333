import { Link, graphql, useStaticQuery } from "gatsby";
import React, { useContext, useEffect, useState } from "react";
import styled, { css } from "styled-components";

import * as colors from "../../constants/colors";
import * as routes from "../../constants/routes";
import * as adminUtils from "../../utils/admin";
import FirebaseContext from "../Firebase/FirebaseContext";
import { Button, Flex, FlexWrap } from "../atoms";
import AuthUserContext from "../authentication/AuthUserContext";
import UserContext from "../authentication/UserContext";
import Emoji from "../emoji";
import ProfilePicture from "./ProfilePicture";

const Menu = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  gap: 1rem;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  font-weight: 600;
  color: ${(props) => props.theme.colors.textColor};
`;

const Home = styled.p`
  font-family: "Poppins";
  font-size: 2rem;
  font-weight: 800;
  margin-bottom: 0;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-right: 0.5rem;
  line-height: 1.1;
  letter-spacing: 2px;
  text-transform: uppercase;
  @media (max-width: 500px) {
    font-size: 1.3rem;
    letter-spacing: 0;
  }
`;

const Wrapper = styled(FlexWrap)`
  padding: 1rem;
  flex-shrink: 0;
  background-color: ${(props) => props.theme.colors.secondaryBackground};
  box-shadow:
    0 2px 2px 0 rgba(111, 111, 111, 0.2),
    0 2px 2px 0 rgba(111, 111, 111, 0.2);
`;

const Navigation = () => {
  const authUser = useContext(AuthUserContext);

  const { site, allUser } = useStaticQuery(graphql`
    query NavigationQuery {
      site {
        siteMetadata {
          title
        }
      }
      allUser {
        edges {
          node {
            id
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <Flex
        css={css`
          flex-grow: 1;
          align-items: center;
        `}
      >
        <Link style={{ color: colors.RED }} to={routes.LANDING}>
          <Home>{site.siteMetadata.title}</Home>
        </Link>
      </Flex>

      {authUser ? (
        <NavigationAuth buildTimeUsers={allUser} />
      ) : (
        <NavigationNonAuth />
      )}
    </Wrapper>
  );
};

const MessageIconLink = styled(StyledLink)`
  font-size: 2rem;
`;

const StyledLinkOnDesktop = styled(StyledLink)`
  @media (max-width: 500px) {
    display: none;
  }
`;

const Dot = styled.div`
  width: 1.2rem;
  height: 1.2rem;
  background: ${colors.RED};
  border: 3px solid ${colors.WHITE};
  border-radius: 100%;
  position: absolute;
  top: 0.2rem;
  right: 0.2rem;
`;

const NavigationAuth = ({ buildTimeUsers }) => {
  const firebase = useContext(FirebaseContext);
  const authUser = useContext(AuthUserContext);
  const user = useContext(UserContext);

  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);

  useEffect(() => {
    if (!firebase || !authUser) return;

    firebase.onUserUnreadMessages(authUser.uid, (querySnapshot) => {
      setHasUnreadMessages(!querySnapshot.empty);
    });
  }, [firebase, authUser?.uid]);

  if (!firebase) return;
  if (!authUser) return;
  if (!user) return;

  const link =
    user && buildTimeUsers.edges.some((edge) => edge.node.id === authUser.uid)
      ? `/${user.username}/`
      : routes.SETTINGS;

  return (
    <Menu>
      {adminUtils.isAdmin(authUser) && (
        <StyledLink to={routes.ADMIN}>Admin</StyledLink>
      )}
      <StyledLink to={routes.PHOTOGRAPHERS}>Fotografer</StyledLink>
      <div style={{ position: "relative" }}>
        <MessageIconLink to={routes.MESSAGES}>
          <Emoji symbol="✉️" label="Meddelanden" title="Meddelanden" />
        </MessageIconLink>
        {hasUnreadMessages && <Dot title="Du har olästa meddelanden" />}
      </div>
      <Link to={link}>
        <ProfilePicture
          user={user}
          size="small"
          style={{
            margin: "0",
            verticalAlign: "bottom",
            border: `2px solid ${colors.BLUE}`,
          }}
        />
      </Link>
    </Menu>
  );
};

const NavigationNonAuth = () => (
  <Menu>
    <StyledLink to={routes.PHOTOGRAPHERS}>Fotografer</StyledLink>
    <StyledLinkOnDesktop to={routes.SIGN_IN}>Logga in</StyledLinkOnDesktop>
    <Link to={routes.WAIT_LIST}>
      <Button
        tabIndex={-1}
        $blue
        style={{
          padding: "8px",
          paddingLeft: "18px",
          paddingRight: "18px",
          fontSize: "1rem",
          fontWeight: "600",
          letterSpacing: "1px",
        }}
      >
        Bli fotograf
      </Button>
    </Link>
  </Menu>
);

export default Navigation;
