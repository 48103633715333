import { useLayoutEffect, useState } from "react";

export const useDarkMode = () => {
  const [darkMode, setDarkMode] = useState<boolean>();

  useLayoutEffect(() => {
    const darkModeQuery = window.matchMedia("(prefers-color-scheme: dark)");
    setDarkMode(darkModeQuery.matches);

    const update = (event) => {
      setDarkMode(event.matches);
    };

    darkModeQuery.addEventListener("change", update);

    return () => {
      darkModeQuery.removeEventListener("change", update);
    };
  }, []);

  return darkMode;
};
