const prodConfig = {
  cloudName: "pictureit",
  uploadPreset: "otvcdezb",
  pathToUserImagesFolder: "production/user_images/",
  pathToProfilePictureFolder: "production/user_profile/",
  maxFiles: 15,
  premiumMaxFiles: 50,
  betaMaxFiles: 50,
} as const;

const devConfig = {
  cloudName: "pictureit-development",
  uploadPreset: "ia7oowq9",
  pathToUserImagesFolder: "development/user_images/",
  pathToProfilePictureFolder: "development/user_profiles/",
  maxFiles: 10,
  premiumMaxFiles: 20,
  betaMaxFiles: 15,
} as const;

const config = process.env.NODE_ENV === "production" ? prodConfig : devConfig;

const cloudinary = {
  CLOUD_NAME: config.cloudName,
  UPLOAD_PRESET: config.uploadPreset,
  PATH_TO_USER_IMAGES_FOLDER: config.pathToUserImagesFolder,
  PATH_TO_PROFILE_PICTURE_FOLDER: config.pathToProfilePictureFolder,
  BASE_URL: `https://res.cloudinary.com/${config.cloudName}/image/upload/`,
  MAX_FILES: config.maxFiles,
  PREMIUM_MAX_FILES: config.premiumMaxFiles,
  BETA_MAX_FILES: config.betaMaxFiles,
} as const;

export default cloudinary;
