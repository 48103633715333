import { Slice } from "gatsby";
import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { createGlobalStyle, ThemeProvider } from "styled-components";

import * as colors from "../constants/colors";
import * as crisp from "../utils/crisp";
import withAuthentication from "./authentication/withAuthentication";
import { UploadWidgetWrapper } from "./cloudinary/UploadWidgetContext";
import getFirebase, { Firebase } from "./Firebase";
import FirebaseContext from "./Firebase/FirebaseContext";
import RefreshUsersContext from "./Firebase/RefreshUsersContext";
import UsersContext, { UserWithId } from "./Firebase/UsersContext";
import Navigation from "./misc/Navigation";

import darkTheme from "./themes/dark";
import lightTheme from "./themes/light";
import { useDarkMode } from "./themes/use-dark-mode";

const GlobalStyle = createGlobalStyle`
  html, body, #___gatsby, #___gatsby > div {
    height: 100%;
  }

  #___gatsby > div {
    display: flex;
    flex-direction: column;
  }
  
  a {
    transition: filter 0.2s ease;
  }
  a:hover {
    filter: brightness(105%);
  }
  
  // TODO: move as much as possible of this to atoms
  body {
    background: ${(props) => props.theme.colors.background};

    ${(props) =>
      props.theme.colors.isDark &&
      `
      color: ${colors.WHITE};
    `}

    
    a {
      ${(props) =>
        props.theme.colors.isDark &&
        `
        color: ${colors.WHITE};
      `}
    }

    select, input, textarea {
      &:disabled {
        background-color: ${(props) =>
          props.theme.colors.isDark ? `grey` : `#f4f4f4`};
      }

      ${(props) =>
        props.theme.colors.isDark &&
        `
        background-color: #535353;
        border-color: slategrey;
        color: ${colors.WHITE};
      `}
    }
  }
`;

// TODO: SEO component should probably move into the layout component
const Layout = (props) => {
  const [firebase, setFirebase] = useState<Firebase>();
  const [users, setUsers] = useState<UserWithId[]>();

  const loadFirebase = async () => {
    const app = import("firebase/compat/app");
    const auth = import("firebase/compat/auth");
    const database = import("firebase/compat/firestore");
    const functions = import("firebase/compat/functions");
    const analytics = import("firebase/compat/analytics");
    const performance = import("firebase/compat/performance");

    const values = await Promise.all([
      app,
      auth,
      database,
      functions,
      analytics,
      performance,
    ]);

    const firebase = getFirebase(values[0].default);
    setFirebase(firebase);
    // refreshUsers(); // only do this for logged in users
  };

  useEffect(() => {
    loadFirebase();
    crisp.enableSafeMode();
  }, []);

  const refreshUsers = async () => {
    const snapshot = await firebase?.onceGetUsers();
    const usersWithIds = snapshot.docs.map((user) => ({
      ...user.data(),
      id: user.id,
    }));
    setUsers(usersWithIds);
  };

  return (
    <FirebaseContext.Provider value={firebase}>
      <RefreshUsersContext.Provider value={refreshUsers}>
        <UsersContext.Provider value={users}>
          <AppWithAuthentication {...props} />
        </UsersContext.Provider>
      </RefreshUsersContext.Provider>
    </FirebaseContext.Provider>
  );
};

const AppWithAuthentication = withAuthentication((props) => {
  const darkMode = useDarkMode();
  const theme = darkMode ? darkTheme : lightTheme;

  return (
    <ThemeProvider theme={theme}>
      <UploadWidgetWrapper>
        <Helmet>
          <html lang="sv" />
        </Helmet>
        <GlobalStyle />
        <Navigation />
        <div style={{ flexGrow: 1 }}>{props.children}</div>
        <Slice alias="footer" theme={theme} />
      </UploadWidgetWrapper>
    </ThemeProvider>
  );
});

export default Layout;
