import React, { createContext } from "react";
import { Firebase } from ".";

const FirebaseContext = createContext<Firebase | undefined>(undefined);

export const withFirebase = (Component) => (props) => (
  <FirebaseContext.Consumer>
    {(firebase) => <Component {...props} firebase={firebase} />}
  </FirebaseContext.Consumer>
);

export default FirebaseContext;
