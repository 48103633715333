import React from "react";

const Emoji = (
  props: { symbol: string; label?: string } & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  >,
) => (
  <span
    className="emoji"
    role="img"
    aria-label={props.label ? props.label : ""}
    aria-hidden={props.label ? "false" : "true"}
    {...props}
  >
    {props.symbol}
  </span>
);

export default Emoji;
