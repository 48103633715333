declare global {
  interface Window {
    $crisp?: any;
  }
}

export const enableSafeMode = () => {
  if (window && window.$crisp && process.env.NODE_ENV === "production") {
    window.$crisp.push(["safe", true]);
  }
};

export const openChatBox = () => {
  if (window && window.$crisp) {
    window.$crisp.push(["do", "chat:open"]);
  }
};

export const setCrispUserField = (field, value) => {
  if (window && window.$crisp && field && value) {
    window.$crisp.push([`set`, `user:${field}`, [value]]);
  }
};
