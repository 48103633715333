import React from "react";

const RefreshUsersContext = React.createContext<(() => void) | undefined>(
  undefined,
);

export const withRefreshUsers = (Component) => (props) => (
  <RefreshUsersContext.Consumer>
    {(refreshUsers) => <Component {...props} refreshUsers={refreshUsers} />}
  </RefreshUsersContext.Consumer>
);

export default RefreshUsersContext;
