import styled, { css } from "styled-components";

import * as colors from "../constants/colors";

export const Card = styled.div<{ $nohover: boolean }>`
  background: ${(props) => props.theme.colors.secondaryBackground};

  box-shadow:
    0 1px 1px 0 rgba(111, 111, 111, 0.1),
    0 1px 1px 0 rgba(111, 111, 111, 0.1);

  &:hover {
    box-shadow:
      0 3px 3px 0 rgba(111, 111, 111, 0.2),
      0 3px 3px 0 rgba(111, 111, 111, 0.2);
  }

  ${(props) =>
    props.$nohover &&
    css`
      &:hover {
        box-shadow:
          0 1px 1px 0 rgba(111, 111, 111, 0.1),
          0 1px 1px 0 rgba(111, 111, 111, 0.1);
      }
    `}
`;

export const Container = styled.div<{ $small?: boolean }>`
  max-width: 1100px;
  margin: auto;
  padding: 1rem;

  ${(props) =>
    props.$small &&
    css`
      max-width: 50rem;
    `}
`;

export const Badge = styled.span<{ $small?: boolean }>`
  width: fit-content;
  background: ${colors.GOLD};
  padding: 0.2rem 0.8rem;
  border-radius: 1rem;
  margin: 1rem 0;
  color: white;
  font-weight: 500;
  letter-spacing: 0.2px;

  ${(props) =>
    props.$small &&
    css`
      font-size: 0.8rem;
    `}
`;

export const Button = styled.button<{
  $red?: boolean;
  $secondaryRed?: boolean;
  $blue?: boolean;
  $secondaryBlue?: boolean;
  $secondaryBlack?: boolean;
  $gold?: boolean;
  $noBorder?: boolean;
  $small?: boolean;
  $brightOnHover?: boolean;
}>`
  padding: 0.5rem 1.3rem;
  border: 0.5px solid #dadada;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  letter-spacing: 1px;
  background-color: ${(props) => props.theme.colors.buttonBackground};
  color: inherit;

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  ${(props) =>
    props.$red &&
    css`
      background-color: ${colors.RED};
      border: 2px solid ${colors.RED};
      color: ${colors.WHITE};
    `}

  ${(props) =>
    props.$secondaryRed &&
    css`
      border: 2px solid ${colors.RED};
      color: ${colors.RED};
      background-color: ${colors.WHITE};
    `}

  ${(props) =>
    props.$blue &&
    css`
      background-color: ${colors.BLUE};
      border: 2px solid ${colors.BLUE};
      color: ${colors.WHITE};
    `}

  ${(props) =>
    props.$secondaryBlue &&
    css`
      border: 2px solid ${colors.BLUE};
      color: ${colors.BLUE};
      background-color: ${colors.WHITE};
    `}

  ${(props) =>
    props.$secondaryBlack &&
    css`
      border: 2px solid ${colors.BLACK};
      color: ${colors.BLACK};
      background-color: ${colors.WHITE};
    `}

    ${(props) =>
    props.$gold &&
    css`
      background-color: ${colors.GOLD};
      border: 2px solid ${colors.GOLD};
      color: ${colors.WHITE};
    `}

  ${(props) =>
    props.$noBorder &&
    css`
      border: 0px solid ${colors.WHITE};
    `}

  ${(props) =>
    props.$small &&
    css`
      padding: 0.4rem 0.7rem;
    `}

  ${(props) =>
    props.$brightOnHover &&
    css`
      transition: filter 0.2s ease;
      &:hover {
        filter: brightness(105%);
      }
    `}
`;

export const ButtonUnstyled = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;

  transition: filter 0.2s ease;
  &:hover {
    filter: brightness(105%);
  }
`;

export const Checkbox = styled.input<{ $primary: boolean }>`
  margin-right: 0.5rem;
  cursor: pointer;

  &::-webkit-input-placeholder {
    color: #bdbdbd;
  }

  ${(props) =>
    props.$primary &&
    css`
      color: palevioletred;
    `}
`;

export const Divider = styled.hr`
  margin-top: 2rem;
  margin-bottom: 2rem;
  max-width: 330px;
  background-color: #f2f2f2;
  height: 2px;
`;

export const Flex = styled.div`
  display: flex;
`;

export const FlexWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Input = styled.input<{ $primary?: boolean }>`
  height: 40px;
  width: 22rem;
  padding-left: 0.7rem;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  border-radius: 4px;
  border: 0.8px solid lightgrey;
  font-size: 1rem;

  &::-webkit-input-placeholder {
    color: #bbbbbb;
  }

  ${(props) =>
    props.$primary &&
    css`
      color: palevioletred;
    `}
`;

export const Label = styled.label`
  color: ${(props) => props.theme.colors.secondaryTextColor};
  display: flex;
  padding-left: 2px;
  font-size: 0.9rem;
`;

export const LabelAndInput = styled.div`
  display: flex;
  flex-direction: column;

  &::placeholder {
    color: #bbbbbb;
  }
  select:disabled {
    background: #dddddd;
  }
`;

export const Select = styled.select<{ $small?: boolean }>`
  color: ${(props) => props.theme.colors.textColor};
  height: 40px;
  width: 22rem;
  margin-bottom: 2rem;
  margin-top: 0.3rem;
  border-radius: 4px;
  padding-left: 5px;

  &::disabled {
    opacity: 0.5;
  }

  ${(props) =>
    props.$small &&
    css`
      height: 2.5rem;
    `}
`;

export const SocialLink = styled.span`
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
`;

export const Textarea = styled.textarea`
  width: 100%;
  max-width: 45rem;
  resize: vertical;
  border: 1px solid lightgrey;
  border-radius: 0.2rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  padding-top: 0.5rem;
  padding-left: 1rem;

  &::-webkit-input-placeholder {
    color: #bbbbbb;
  }
`;

export const TruncatedText = styled.span<{ lines: number }>`
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: ${(p) => p.lines};
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
`;

export const Message = styled.p<{
  $success?: boolean;
  $error?: boolean;
  $warning?: boolean;
  $info?: boolean;
}>`
  padding: 1rem 0rem;
  font-weight: 600;

  ${(props) =>
    props.$success &&
    css`
      color: #00a86c;
    `}

  ${(props) =>
    props.$error &&
    css`
      color: #a80032;
    `}

  ${(props) =>
    props.$warning &&
    css`
      color: #ed9d00;
    `}

  ${(props) =>
    props.$info &&
    css`
      color: #000baf;
    `}
`;
