import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import Gravatar from "react-gravatar";

import cloudinary from "../../utils/cloudinary";

const getPhotoByWidth = (size, publicId) =>
  `${cloudinary.BASE_URL}c_fill,q_auto:good,f_auto,w_${size},h_${size}/v1/${publicId}`;

const defaultStyle = {
  borderRadius: "2rem",
  marginBottom: "0rem",
};

const ProfilePicture = ({ user, size, style }) =>
  user &&
  (getImage(user.profilePictureLocal) ? (
    <GatsbyImage
      alt={`${user.firstName} ${user.lastName}`}
      image={getImage(user.profilePictureLocal)}
      imgStyle={{
        marginLeft: 0,
        marginRight: 0,
        marginBottom: 0,
      }}
      style={Object.assign(
        {
          width: size && size === "small" ? "50px" : "200px",
          height: size && size === "small" ? "50px" : "200px",
          minWidth: size && size === "small" ? "50px" : "200px",
        },
        defaultStyle,
        style,
      )}
    />
  ) : user.profilePicture ? (
    <img
      alt={`${user.firstName} ${user.lastName}`}
      title={`${user.firstName} ${user.lastName}`}
      width={size && size === "small" ? "50" : "200"}
      height={size && size === "small" ? "50" : "200"}
      src={getPhotoByWidth(
        size && size === "small" ? 50 : 200,
        user.profilePicture.publicId,
      )}
      srcSet={`${getPhotoByWidth(
        50,
        user.profilePicture.publicId,
      )} 50w, ${getPhotoByWidth(
        100,
        user.profilePicture.publicId,
      )} 100w, ${getPhotoByWidth(
        200,
        user.profilePicture.publicId,
      )} 200w ,${getPhotoByWidth(400, user.profilePicture.publicId)} 400w`}
      sizes={size && size === "small" ? "50px" : "200px"}
      style={Object.assign({}, defaultStyle, style)}
    />
  ) : (
    <Gravatar
      email={user && user.email}
      size={size && size === "small" ? 50 : 200}
      default="mp"
      style={Object.assign({}, defaultStyle, style)}
      alt={`${user.firstName} ${user.lastName}`}
      title={`${user.firstName} ${user.lastName}`}
    />
  ));

export default ProfilePicture;
