import * as colors from "../../constants/colors";
import { Theme } from "./types";

const dark: Theme = {
  colors: {
    isDark: true,
    background: colors.BLACK,
    secondaryBackground: `#1d1d1d`,
    footerBackground: `#1d1d1d`,
    buttonBackground: `grey`,
    textColor: colors.WHITE,
    secondaryTextColor: `lightgrey`,
    tertiaryTextColor: `grey`,
  },
} as const;

export default dark;
