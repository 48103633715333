declare global {
  interface Window {
    ga?: any;
  }
}

export const setGoogleAnalyticsUserId = (userId) => {
  if (window && window.ga && userId) {
    window.ga(`set`, `userId`, userId);
  }
};
